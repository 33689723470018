import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SidebarClick } from '../../routes/route';
import { getReq, getTable } from '../../requests/request';
import { notifyError } from '../../parts/Toast';
import {Col,Card,Table, Spinner} from "react-bootstrap";
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import MoreDetails from '../../parts/MoreDetails';
import moment from 'moment/moment';
import { IMAGES } from '../../constant/theme';
import Frame from "../../components/frame";
import DownloadExcel from '../../parts/DownloadExcel';
export default function SubscriptionList() {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [subscriptionsListData,setSubscriptionsListData] = useState('');
    const [refrashPage,setRefrashPage] = useState(false);
    const [filterShow,setFilterShow] = useState(false);
    const [moreDetails,setMoreDetails] = useState(null);
    const [filterPackage,setFilterPackage] = useState(null);
    const [exportData,setExportData] = useState('');
    let countTable = 0;
    let durationanim = 0.15;
    let AmountSymbol = '$';
    const list=[
        {'label':'Username','data':'username','type':'text'},
        {'label':'Package','data':'package','type':'option','value':filterPackage},
        {'label':'Status','data':'status','type':'option','value':[{'key':'Active','value':'1'},{'key':'Inactive','value':'2'}]},
    ]
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/subscriptions');
        setCollapseId(12);
        (async()=>{
            try{
                //setSpinner(true);
                let r = await getReq('subscriptions?export=true',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setSubscriptionsListData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setMoreDetails(r.more_details);
                    setFilterPackage(r.package_data);
                    //setSpinner(false);
                }
                if(r.errors){
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                //setSpinner(false);
                notifyError("Request failed please login again");
            }
        })()
    },[refrashPage]);
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setSubscriptionsListData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/subscriptions?export=true&page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setSubscriptionsListData(r.data);
                setExportData(r.export_data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    function filterFunData(r){
        setSubscriptionsListData(r.data);
        setExportData(r.export_data);
        setTable(r.data.data);
        setMoreDetails(r.more_details);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
        {filterShow&&<Filter props={{setFilterShow,url:`${process.env.REACT_APP_BASE_URL}/subscriptions?export=true`,list,filterFunData}}/>}
        <div className="d-flex align-items-center mb-4">
            <div className="flex-fill">
                <h1 className="page-header mb-0">
                Subscription List
                </h1>
            </div>
            <div className="ms-auto">
                {table&&<DownloadExcel props={{exportData: exportData,name:'Subscriptionlist.xlsx'}}/>}
            </div>
        </div>
        {moreDetails&&<MoreDetails props={moreDetails}/>}
        {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
            <span class="sr-only"></span>
        </div>}
        {table&&<div className="card">
            <div className="card-header align-items-center fw-bold small d-flex">
                <span className="flex-grow-1">User Subscription List</span>
                <span className="btn btn-outline-light btn-sm" onClick={()=>setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span>
            </div>
            <div className="card-body">
            <div className="mb-4">
                <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
            </div>
                {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                    <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                </div>}
                <Table responsive style={{textAlign:'center'}} ref={tableRef}>
                    <thead>
                        <tr>
                            <th>
                                <strong>Username</strong>
                            </th>
                            <th>
                                <strong>Subscription Done By</strong>
                            </th>
                            <th>
                                <strong>Package</strong>
                            </th>
                            {/* <th>
                                <strong>Type</strong>
                            </th> */}
                            <th>
                                <strong>Invoice Id</strong>
                            </th>
                            <th>
                                <strong>Price</strong>
                            </th>
                            <th>
                                <strong>Amount Paid</strong>
                            </th>
                            {/* <th>
                                <strong>End Date</strong>
                            </th> */}
                            <th>
                                <strong>Activation Date</strong>
                            </th>
                            <th>
                                <strong>Status</strong>
                            </th>
                        </tr>
                    </thead>
                    {table&&<tbody>
                        {
                            table.map((data,idx)=>{
                            countTable +=1;
                            return(<motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                                <td>{data.username}</td>
                                <td>{data.subs_admin?data.subs_admin:data.s_done_by}</td>
                                <td>{data.package}</td>
                                {/* <td>{data.type?.replace('_',' ')}</td> */}
                                <td>{data.invoice_id}</td>
                                <td>{process.env.REACT_APP_Amount_Symbol||'$'} {parseFloat(data.price)}</td>
                                <td>{process.env.REACT_APP_Amount_Symbol||'$'} {parseFloat(data.amount_paid)}</td>
                                {/* <td>{data.end_date}</td> */}
                                <td>{moment(data.created_at).format('DD-MM-YYYY')||'-'}</td>
                                <td>
                                    {data.status==1 && <span  className="badge light btn btn-success">Active</span>}
                                    {data.status==2 && <span  className="badge light btn btn-danger">Inactive</span>}
                                </td>
                            </motion.tr>)
                            })
                        }
                    </tbody>}
                    {
                        countTable===0&& <div style={{display:'table-caption',marginTop:'1rem',textAlign:'center'}}>No Data For Action!!!</div>
                    }
                </Table>
                {table&&
                <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                    <p className="h6">
                        Showing <span className="font-medium">{subscriptionsListData.from||'-'}</span> to <span className="font-medium">{subscriptionsListData.to||'-'}</span> of{' '}
                        <span className="font-medium">{subscriptionsListData.total}</span> results
                    </p>
                    <div className="d-flex">
                        <nav aria-label="Page navigation">
                            <ul className="pagination mb-0">
                                <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(subscriptionsListData.prev_page_url)}>
                                    <a className="page-link" aria-label="Previous">
                                        <span className="btn-link cursor-pointer">&laquo;</span>
                                        
                                    </a>
                                </li>
                                <li className="page-item" style={{cursor:'pointer'}}>
                                    <a className="page-link">{subscriptionsListData.current_page}</a>
                                </li>
                                <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(subscriptionsListData.next_page_url)}>
                                    <a className="page-link" aria-label="Next">
                                        <span className="btn-link ">&raquo;</span>
                                        
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>}
            </div>
            <div className="card-arrow">
                <div className="card-arrow-top-left"></div>
                <div className="card-arrow-top-right"></div>
                <div className="card-arrow-bottom-left"></div>
                <div className="card-arrow-bottom-right"></div>
            </div>
        </div>}
        
        </Frame>
    )
}
