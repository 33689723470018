import React, { useState, useEffect, useContext } from 'react';
import { getReq } from '../requests/request';
import { notifyError, notifySuccess } from '../parts/Toast';
import { useNavigate } from 'react-router-dom';
import { SidebarClick } from '../routes/route';
import Frame from "../components/frame";

export default function Dashboard() {
    const currency = "$ ";
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [refrashPage, setRefrashPage] = useState(false);
    const [data, setData] = useState('');
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/');
        setCollapseId(0);
        (async () => {
            try {
                setSpinner(true);
                let r = await getReq('dashboard', sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setData(r.data);
                    setSpinner(false);
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                if (err.message) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
            setSpinner(false);
        })()
    }, [refrashPage]);
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Dashboard</h1>
            </div>

            {/* {!data&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>} */}

            {data &&

                <div className="welocme_back_box">
                    <div className="d-flex flex-column">
                        <h4 classNmae="mb-1">Welcome back <span>{data?.username}.</span></h4>
                        <p className='mb-0'>{`Your last login was ${data?.last_login_at}.`}</p>
                    </div>
                </div>
            }

            {!data &&
                <div class="loader card card-bg d-flex flex-column" style={{ width: 'unset' }}>
                    <div class="wrapper">
                        <div class="circle"></div>
                        {/* <div class="line-1"></div>
                        <div class="line-2"></div> */}
                        <div class="line-3"></div>
                        <div class="line-4"></div>
                    </div>
                </div>
            }

            {/* {!data&&<div className="row g-3 mt-2" style={{display:'flex',justifyContent:'center',gap:'40px'}}>
                {(()=>{
                    let arr = [];
                    for (let i = 0; i < 12; i++){
                        arr.push(<div class="loader col-xl-3 col-lg-6 card card-bg" key={i}>
                            <div class="wrapper">
                                <div class="circle"></div>
                                <div class="line-1"></div>
                                <div class="line-2"></div>
                                <div class="line-3"></div>
                                <div class="line-4"></div>
                            </div>
                        </div>)
                    }
                    return arr;
                })()}
            </div>} */}

            {data && <div className="row g-3">
                {/* <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Pending Kyc
                            </div>
                            <h3 className="mb-0">{data?.pending_kyc}</h3>
                        </div>
                       
                    </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Completed Kyc
                            </div>
                            <h3 className="mb-0">{data?.completed_kyc}</h3>
                        </div>
                       
                    </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Rejected Kyc
                            </div>
                            <h3 className="mb-0">{data?.rejected_kyc}</h3>
                        </div>
                       
                    </div>
                </div> */}
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Pending Deposit
                            </div>
                            <h3 className="mb-0">{data?.pending_deposit || 0}</h3>
                        </div>

                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Completed Deposit
                            </div>
                            <h3 className="mb-0">{data?.completed_deposit || 0}</h3>
                        </div>

                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Rejected Deposit
                            </div>
                            <h3 className="mb-0">{data?.rejected_deposit || 0}</h3>
                        </div>

                    </div>
                </div>
                {/* <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Pending Withdraw
                            </div>
                            <h3 className="mb-0">{data?.pending_withdraw || 0}</h3>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Completed Withdraw
                            </div>
                            <h3 className="mb-0">{data?.completed_withdraw || 0}</h3>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Rejected Withdraw
                            </div>
                            <h3 className="mb-0">{data?.rejected_withdraw || 0}</h3>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                            System Withdraw
                            </div>
                            <h3 className="mb-0">{data.withdraw_taken || 0}</h3>
                        </div>
                       
                    </div>
                </div> */}
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                System Subscription
                            </div>
                            <h3 className="mb-0">{data?.total_subscription || 0}</h3>
                        </div>

                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Active Subscription
                            </div>
                            <h3 className="mb-0">{data?.active_subscription || 0}</h3>
                        </div>

                    </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                                Inactive Subscription
                            </div>
                            <h3 className="mb-0">{data?.inactive_subscription || 0}</h3>
                        </div>

                    </div>
                </div>
                {/* <div className="col-xl-3 col-lg-6">
                    <div className="card mb-1 card-bg">
                        <div className="card-body">
                            <div className="d-flex fw-bold small mb-3">
                            System Users
                            </div>
                            <h3 className="mb-0">{data.total_users}</h3>
                        </div>
                       
                    </div>
                </div> */}
                <div className="row g-3">
                    <div className="col-xl-6 col-lg-6">
                        <div className="card mb-1">
                            <div className="card-body">
                                <div className="d-flex fw-bold small mb-3">
                                    Active Users
                                </div>
                                <h3 className="mb-2">{data?.active_users}</h3>
                                <p className="mb-2">From all user</p>
                                <div className="progress">
                                    <div className="progress-bar bg-theme" style={{ width: `${(parseFloat(data.active_users) / parseFloat(data.total_users)) * 100}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card mb-1">
                            <div className="card-body">
                                <div className="d-flex fw-bold small mb-3">
                                    Inactive Users
                                </div>
                                <h3 className="mb-2">{data?.inactive_users}</h3>
                                <p className="mb-2">From all user</p>
                                <div className="progress">
                                    <div className="progress-bar bg-theme" style={{ width: `${(parseFloat(data.inactive_users) / parseFloat(data.total_users)) * 100}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </Frame>
    )
}
