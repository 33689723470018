import React, { useContext, useEffect, useRef, useState } from 'react';
import {Col,Card,Table, Spinner,Dropdown} from "react-bootstrap";
import { getReq,getTable, postReq } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import { IMAGES, SVGICON } from "../../constant/theme";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import openImg from '../../parts/ImageNewTab';
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import DownloadExcel from '../../parts/DownloadExcel';
import MoreDetails from '../../parts/MoreDetails';
import moment from 'moment/moment';
import Frame from "../../components/frame";
export default function ManualdDepositList() {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [depositData,setDepositData] = useState();
    const [refrashPage,setRefrashPage] = useState(false);
    const [searchBy,setSearchBy] = useState('');
    const [searchByText,setSearchByText] = useState('');
    const [selectUsername,setSelectUsername] = useState(null);
    const [selectAmount,setSelectAmount] = useState(0);
    const [loader,setLoader] = useState(false);
    const [showApproveModel,setShowApproveModel] = useState(false);
    const [showRejectModel,setShowRejectModel] = useState(false);
    const [modelApproveData,setModelApproveData] = useState({'id':'','received_amount':'','comment':''});
    const [modelRejectData,setModelRejectData] = useState({'id':'','reason':''});
    const [filterShow,setFilterShow] = useState(false);
    const [moreDetails,setMoreDetails] = useState('');
    const [exportData,setExportData] = useState('');
    let countTable = 0;
    let durationanim = 0.1;
    let AmountSymbol = '$';
    const list=[
        {'label':'Username','data':'username','type':'text'},
    ]
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/manualDepositList');
        setCollapseId(3);
        (async()=>{
            try{
                //setSpinner(true);
                let r = await getReq('deposit/requests?export=true',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setDepositData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setMoreDetails(r.more_details);
                    //setSpinner(false);
                }
                if(r.errors){
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                if(err.message){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                }
                //setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    },[refrashPage]);
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    );
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/deposit/requests?export=true&page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setDepositData(r.data);
                setExportData(r.export_data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setDepositData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    function filterFunData(r){
        setDepositData(r.data);
        setExportData(r.export_data);
        setTable(r.data.data);
        setMoreDetails(r.more_details);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
             <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                        Deposit List
                    </h1>
                </div>
                <div className="ms-auto">
                    {table&&<DownloadExcel props={{exportData:exportData,name:'Depositlist.xlsx'}}/>}
                </div>
            </div>
            {moreDetails&&<MoreDetails props={moreDetails}/>}
            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}
            {table&&<div className="card">
                <div className="card-header align-items-center fw-bold small d-flex">
                    <span className="flex-grow-1">Deposit List</span>
                    <span className="btn btn-outline-light btn-sm" onClick={() => setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span>
                </div>
                <div className="card-body">
                    {/* <div className="mb-4">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div> */}
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                    <Table responsive style={{textAlign:'center'}} ref={tableRef}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>username</strong>
                                </th>
                                <th>
                                    <strong>Amount</strong>
                                </th>
                                <th>
                                    <strong>Tnx Hash</strong>
                                </th>
                                <th>
                                    <strong>Gas Transfer</strong>
                                </th>
                                <th>
                                    <strong>Gas Transfer Txid</strong>
                                </th>
                                <th>
                                    <strong>Is Collect</strong>
                                </th>
                                <th>
                                    <strong>Collect Txid</strong>
                                </th>
                                <th>
                                    <strong>Gas Transfer Time</strong>
                                </th>
                                <th>
                                    <strong>Is Check Collect Txid</strong>
                                </th>
                                <th>
                                    <strong>Is Check Gas Txid</strong>
                                </th>
                                <th>
                                    <strong>Created At</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>{
                                countTable += 1;
                                return (<motion.tr initial={{ opacity: 0, x: -150 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: durationanim += 0.06 }} key={idx}>
                                    <td>{data.username}</td>
                                    <td>{process.env.REACT_APP_Amount_Symbol||'$'} {parseFloat(data.amount)}</td>
                                    <td>
                                        {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center"}}> */}
                                        <div>
                                            {data.thash}
                                        </div>
                                        {/* </div> */}
                                    </td>
                                    <td>
                                        {data.is_gas_transfer == 0 && <span className="badge light btn btn-warning">Pending</span>}
                                        {data.is_gas_transfer == 1 && <span className="badge light btn btn-success">Successful</span>}
                                        {data.is_gas_transfer != 0 && data.is_gas_transfer != 1 && <span className="badge light btn btn-danger">Error</span>}
                                    </td>
                                    <td>{data.gas_transfer_txid||'-'}</td>
                                    <td>{data.gas_transfer_time||'-'}</td>
                                    <td>
                                        {data.is_collect == 0 && <span className="badge light btn btn-warning">Pending</span>}
                                        {data.is_collect == 1 && <span className="badge light btn btn-success">Successful</span>}
                                        {data.is_collect != 0 && data.is_collect != 1 && <span className="badge light btn btn-danger">Error</span>}
                                    </td>
                                    <td>{data.collect_txid||'-'}</td>
                                    <td>
                                        {data.is_check_collect_txid == 0 && <span className="badge light btn btn-warning">Pending</span>}
                                        {data.is_check_collect_txid == 1 && <span className="badge light btn btn-success">Successful</span>}
                                        {data.is_check_collect_txid != 0 && data.is_check_collect_txid != 1 && <span className="badge light btn btn-danger">Error</span>}
                                    </td>
                                    <td>
                                        {data.is_check_gas_txid == 0 && <span className="badge light btn btn-warning">Pending</span>}
                                        {data.is_check_gas_txid == 1 && <span className="badge light btn btn-success">Successful</span>}
                                        {data.is_check_gas_txid != 0 && data.is_check_gas_txid != 1 && <span className="badge light btn btn-danger">Error</span>}
                                    </td>
                                    <td>{moment(data.created_at).format('DD-MM-YYYY')}</td>
                                    <td>
                                        {data.status == 0 && <span className="badge light btn btn-warning">Pending</span>}
                                        {data.status == 1 && <span className="badge light btn btn-success">Approved</span>}
                                        {data.status == 2 && <span className="badge light btn btn-danger">Rejected</span>}
                                    </td>
                                </motion.tr>)
                                })
                            }
                        </tbody>}
                        {
                            countTable===0&& <div style={{display:'table-caption',marginTop:'1rem',textAlign:'center'}}>No Data For Action!!!</div>
                        }
                    </Table>
                    {table&&
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className="h6">
                            Showing <span className="font-medium">{depositData.from||'-'}</span> to <span className="font-medium">{depositData.to||'-'}</span> of{' '}
                            <span className="font-medium">{depositData.total}</span> results
                        </p>
                        <div className="d-flex">
                           
                            <nav aria-label="Page navigation">
                                <ul className="pagination p-0">
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(depositData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link cursor-pointer">&laquo;</span>
                                        </a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}}>
                                        <a className="page-link">{depositData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(depositData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
                </div>
                
            </div>}
        {filterShow&&<Filter props={{setFilterShow,url:`${process.env.REACT_APP_BASE_URL}/deposit/requests?export=true`,list,filterFunData}}/>}
    </Frame>
    )
}