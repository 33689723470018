import React, {useState,useEffect,Component, useContext, useRef } from 'react';
import {Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarClick } from '../routes/route';
import { logout } from '../requests/request';
import { notifyError, notifySuccess } from '../parts/Toast';
function Leftpart() {
	const scrollRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const { hash, pathname, search } = location;
	const { hideBinary, currentSubmenu, setCurrentSubmenu } = useContext(SidebarClick);
	const [expend, setExpend] = useState(0);
	const [activeId,setActiveId] = useState(null);
	useEffect(() => {
		setExpend(currentSubmenu);
		// function updateScrollPosition(e) {
        //     sessionStorage.setItem('scroll',e.target.scrollTop);
        // }

        // if (scrollRef && scrollRef.current) {
        //     scrollRef.current.addEventListener("scroll", updateScrollPosition, true);
        // }

		// scrollRef.current.scrollTo({x:0,y:sessionStorage.getItem('scroll')});

		// return(()=>{
		// 	scrollRef.current.removeEventListener("scroll", updateScrollPosition, false);
		// })
		
	}, [])
	function closeSub() {
		setExpend(0);
	}
	function openSub(id) {
		setExpend(id);
		setCurrentSubmenu(id);
	}
	async function onLogout() {
		let result = await logout(sessionStorage.getItem('token'));
		if(result.errors){
		  let err = Object.values(result.errors);
		  //err.map((data)=>notifyError(data[0]));
		  notifyError(err[0][0]);
		}
		if(result.status===true){
		  sessionStorage.clear();
		  window.history.replaceState(null, null, "/");
		  navigate('/',{ replace: true });
		  notifySuccess('Logout Successfully');
		  window.location.reload();
		}
	}

    return (
		<>
		<nav id="sidebar" className="sidebar-wrapper">
			<div className="app-brand p-3 mb-3">
				<a href="/admin/dashboard">
					<img src="/assets/img/logo-dark.png" className="logo" alt="" />
					<img src="/assets/img/favicon.png" className="favicon" alt="" />
				</a>
			</div>

			<div className="sidebarMenuScroll" ref={scrollRef}>
				<ul className="sidebar-menu">
					<li>
						<a className={pathname=="/admin/dashboard"?'active':null} href="/admin/dashboard">
							<i className="bi bi-house"></i>
							<span className="menu-text">Dashboard</span>
						</a>
					</li>
					<li>
						<a className={pathname=="/admin/profileSetting"?'active':null} href="/admin/profileSetting">
							<i className="bi bi-person"></i>
							<span className="menu-text">Profile</span>
						</a>
					</li>
					<li>
						<a className={pathname=="/admin/userList"?'active':null} href="/admin/userList">
							<i className="bi bi-people"></i>
							<span className="menu-text">Manage Members</span>
						</a>
					</li>
					<li>
						<a className={pathname=="/admin/balanceReportList"?'active':null} href="/admin/balanceReportList">
							<i className="bi bi-credit-card"></i>
							<span className="menu-text">Balance Report</span>
						</a>
					</li>
					<li>
						<a className={pathname=="/admin/subscriptionDetails"?'active':null} href="/admin/subscriptionDetails">
							<i className="bi bi-stickies"></i>
							<span className="menu-text">Subscription Details</span>
						</a>
					</li>
					<li className={`treeview ${
						(pathname=="/admin/kycStatus"||
						pathname=="/admin/kycDocList"||
						activeId==0)?'active':null
					}`} >
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(0)}><i className="bi bi-file-text"></i>
							<span className="menu-text">KYC</span>
						</a>
						<ul className="treeview-menu">
							<li><a className={pathname=="/admin/kycStatus"?'active':null} href="/admin/kycStatus">KYC Requests</a></li>
							<li><a className={pathname=="/admin/kycDocList"?'active':null} href="/admin/kycDocList">KYC Setting</a></li>
						</ul>
					</li>
					<li className={`treeview ${
						(pathname=="/admin/manualDepositList"||
						activeId==1)?'active':null
					}`}>
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(1)}><i className="bi bi-box-arrow-down-left"></i>
							<span className="menu-text">Deposit</span>
						</a>
						<ul className="treeview-menu">
							<li><a className={pathname=="/admin/manualDepositList"?'active':null} href="/admin/manualDepositList">Deposit List</a></li>
						</ul>
					</li>
					<li className={`treeview ${
						(pathname=="/admin/manualWithdrawList"||
						//pathname=="/admin/manualWithdrawHistory"||
						//pathname=="/admin/userBankList"||
						//pathname=="/admin/userBankHistory"||
						activeId==2)?'active':null
					}`}>
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(2)}><i className="bi bi-box-arrow-up-right"></i>
							<span className="menu-text">Withdraw</span>
						</a>
						<ul className="treeview-menu">
							<li><a className={pathname=="/admin/manualWithdrawList"?'active':null} href="/admin/manualWithdrawList">Withdraw List</a></li>
							{/* <li><a className={pathname=="/admin/userBankList"?'active':null} href="/admin/userBankList">Manage Bank</a></li> */}
							{/* <li><a className={pathname=="/admin/userBankHistory"?'active':null} href="/admin/userBankHistory">Bank History</a></li> */}
						</ul>
					</li>
					{/* <li className={`treeview ${
						(pathname=="/admin/transferList"||
						activeId==3)?'active':null
					}`}>
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(3)}><i className="bi bi-send"></i>
							<span className="menu-text">Transfer</span>
						</a>
						<ul className="treeview-menu">
							<li><a className={pathname=="/admin/transferList"?'active':null} href="/admin/transferList">Transfer Request</a></li>
						</ul>
					</li> */}
					{/* <li className={`treeview ${
						(pathname=="/admin/rankSetting"||
						pathname=="/admin/rankList"||
						activeId==4)?'active':null
					}`} >
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(4)}><i className="bi bi-graph-up-arrow"></i>
							<span className="menu-text">Rank</span>
						</a>
						<ul className="treeview-menu"> */}
							{/* <li><a className={pathname=="/admin/kycList"?'active':null} href="/admin/kycList">KYC List</a></li> */}
							{/* <li><a className={pathname=="/admin/rankSetting"?'active':null} href="/admin/rankSetting">Rank Setting</a></li>
							<li><a className={pathname=="/admin/rankList"?'active':null} href="/admin/rankList">Rank Details</a></li>
						</ul>
					</li> */}
					{/* <li className={`treeview ${
						(pathname=="/admin/roi"||
						pathname=="/admin/binary"||
						pathname=="/admin/unilevel"||
						// pathname=="/admin/superRevenueBonus"||
						// pathname=="/admin/globalPoolBonus"||
						// pathname=="/admin/globalLinePoolBonus"||
						// pathname=="/admin/leadershipPoolRanksAndBusinessFund"||
						activeId==4)?'active':null
					}`}> */}
						{/* <a style={{cursor:'pointer'}} onClick={()=>setActiveId(4)}><i className="bi bi-stickies"></i>
							<span className="menu-text">Incomes</span>
						</a>
						<ul className="treeview-menu"> */}
							{/* <li><a className={pathname=="/admin/roi"?'active':null} href="/admin/roi">Roi</a></li> */}
							{/* <li><a className={pathname=="/admin/superRevenueBonus"?'active':null} href="/admin/superRevenueBonus">Super Revenue</a></li> */}
							{/* {(hideBinary==1)&&
							<li onClick={() => setCurrentSubmenu(5)}>
								<a className={pathname=="/admin/binary"?'active':null} href="/admin/binary">Binary</a>
							</li>
							}
							<li><a className={pathname=="/admin/unilevel"?'active':null} href="/admin/unilevel">Direct Referral</a></li> */}
							{/* <li><a className={pathname=="/admin/globalPoolBonus"?'active':null} href="/admin/globalPoolBonus">Global Pool</a></li> */}
							{/* <li><a className={pathname=="/admin/globalLinePoolBonus"?'active':null} href="/admin/globalLinePoolBonus">Global Line Pool</a></li> */}
							{/* <li><a className={pathname=="/admin/leadershipPoolRanksAndBusinessFund"?'active':null} href="/admin/leadershipPoolRanksAndBusinessFund">Leadership Pool Ranks And<br/> Business Development Fund</a></li> */}
						{/* </ul>
					</li> */}
					<li className={`treeview ${
						(pathname=="/admin/roiIncomes"||
						pathname=="/admin/unilevelIncomes"||
						pathname=="/admin/leadershipIncomes"||
						pathname=="/admin/universelLegIncomes"||
						activeId==4)?'active':null
					}`}>
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(4)}><i className="bi bi-stickies"></i>
							<span className="menu-text">Incomes</span>
						</a>
						<ul className="treeview-menu">
							<li><a className={pathname=="/admin/roiIncomes"?'active':null} href="/admin/roiIncomes">Daily Growth Bonus</a></li>
							<li><a className={pathname=="/admin/unilevelIncomes"?'active':null} href="/admin/unilevelIncomes">Unilevel Incomes</a></li>
							<li><a className={pathname=="/admin/leadershipIncomes"?'active':null} href="/admin/leadershipIncomes">Leadership Incomes</a></li>
							<li><a className={pathname=="/admin/universelLegIncomes"?'active':null} href="/admin/universelLegIncomes">Universel Leg Incomes</a></li>
							{/* <li><a className={pathname=="/admin/globalPoolBonus"?'active':null} href="/admin/globalPoolBonus">Global Pool</a></li> */}
							{/* <li><a className={pathname=="/admin/globalLinePoolBonus"?'active':null} href="/admin/globalLinePoolBonus">Global Line Pool</a></li> */}
							{/* <li><a className={pathname=="/admin/leadershipPoolRanksAndBusinessFund"?'active':null} href="/admin/leadershipPoolRanksAndBusinessFund">Leadership Pool Ranks And<br/> Business Development Fund</a></li> */}
						</ul>
					</li>
					<li className={`treeview ${
						(pathname=="/admin/packageList"||
						pathname=="/admin/packageCreate"||
						activeId==5)?'active':null
					}`}>
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(5)}><i className="bi bi-box"></i>
							<span className="menu-text">Package</span>
						</a>
						<ul className="treeview-menu">
							<li><a className={pathname=="/admin/packageList"?'active':null} href="/admin/packageList">Package List</a></li>
							<li><a className={pathname=="/admin/packageCreate"?'active':null} href="/admin/packageCreate">Create Package</a></li>
						</ul>
					</li>
					{/*
					<li>
						<a className={pathname=="/admin/promotion"?'active':null} href="/admin/promotion">
							<i className="bi bi-shop-window"></i>
							<span className="menu-text">Promotion</span>
						</a>
					</li>
					*/}
					<li className={`treeview ${
						(
						// pathname=="/admin/transactions"||
						pathname=="/admin/payouts"||
						pathname=="/admin/allTransaction"||
						activeId==6)?'active':null
					}`}>
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(6)}><i className="bi bi-currency-dollar"></i>
							<span className="menu-text">Finance</span>
						</a>
						<ul className="treeview-menu">
							{/* <li><a className={pathname=="/admin/transactions"?'active':null} href="/admin/transactions">Transactions</a></li> */}
							<li><a className={pathname=="/admin/payouts"?'active':null} href="/admin/payouts">Payouts</a></li>
							<li><a className={pathname=="/admin/allTransaction"?'active':null} href="/admin/allTransaction">Account Statement</a></li>
						</ul>
					</li>
					<li className={`treeview ${
						(pathname=="/admin/subscriptionList"||
						pathname=="/admin/package"||
						// pathname=="/admin/manageSubscription"||
						activeId==7)?'active':null
					}`}>
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(7)}><i className="bi bi-gem"></i>
							<span className="menu-text">Manage<br/> Subscriptions</span>
						</a>
						<ul className="treeview-menu">
							<li><a className={pathname=="/admin/package"?'active':null} href="/admin/package">Purchase Package</a></li>
							<li><a className={pathname=="/admin/subscriptionList"?'active':null} href="/admin/subscriptionList">Subscription List</a></li>
							{/* <li><a className={pathname=="/admin/manageSubscription"?'active':null} href="/admin/manageSubscription">Cancellation Request</a></li> */}
						</ul>
					</li>
					{/* <li className={`treeview ${
						(pathname=="/admin/botpackageList"||
						pathname=="/admin/botpackageCreate"||
						activeId==8)?'active':null
					}`}>
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(8)}><i className="bi bi-boxes"></i>
							<span className="menu-text">Bot Package</span>
						</a>
						<ul className="treeview-menu">
							<li><a className={pathname=="/admin/botpackageList"?'active':null} href="/admin/botpackageList">Package List</a></li>
							<li><a className={pathname=="/admin/botpackageCreate"?'active':null} href="/admin/botpackageCreate">Create Package</a></li>
						</ul>
					</li> */}
					{/* <li className={`treeview ${
						(pathname=="/admin/botsubscriptionList"||
						pathname=="/admin/botsubscriptioncancelList"||
						pathname=="/admin/manageSubscription"||
						activeId==9)?'active':null
					}`}>
						<a style={{cursor:'pointer'}} onClick={()=>setActiveId(9)}><i className="bi bi-robot"></i>
							<span className="menu-text">Manage Bot<br/> Subscriptions</span>
						</a>
						<ul className="treeview-menu">
							<li><a className={pathname=="/admin/botsubscriptionList"?'active':null} href="/admin/botsubscriptionList">Subscription List</a></li>
							<li><a className={pathname=="/admin/botsubscriptioncancelList"?'active':null} href="/admin/botsubscriptioncancelList">Cancellation Request</a></li>
							<li><a className={pathname=="/admin/manageSubscription"?'active':null} href="/admin/manageSubscription">Cancellation Request</a></li>
						</ul>
					</li> */}
					{/* <li>
						<a className={pathname=="/admin/poolSetting"?'active':null} href="/admin/poolSetting">
							<i className="bi bi-sliders"></i>
							<span className="menu-text">Global Pool Setting</span>
						</a>
					</li> */}
					<li>
						<a className={pathname=="/admin/setting"?'active':null} href="/admin/setting">
							<i className="bi bi-gear"></i>
							<span className="menu-text">Administration Setting</span>
						</a>
					</li>
					{/* <li>
						<a className={pathname=="/admin/news"?'active':null} href="/admin/news">
							<i class="bi bi-newspaper"></i>
							<span className="menu-text">News</span>
						</a>
					</li> */}
					<li>
						<a className={pathname=="/admin/logs"?'active':null} href="/admin/logs">
							<i className="bi bi-box-arrow-in-right"></i>
							<span className="menu-text">Logs</span>
						</a>
					</li>
					<li>
						<a className={pathname=="/admin/leadershipRank"?'active':null} href="/admin/leadershipRank">
							<i className="bi bi-cash-stack"></i>
							<span className="menu-text">Leadership Rank</span>
						</a>
					</li>
					<li>
						<a className={pathname=="/admin/leadershipLog"?'active':null} href="/admin/leadershipLog">
							<i className="bi bi-card-list"></i>
							<span className="menu-text">Leadership Details</span>
						</a>
					</li>
				</ul>
			</div>
		</nav>
		</>
      );
    }

  export default Leftpart;