import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Card, Table, Spinner } from "react-bootstrap";
import { getReq, getTable } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import DownloadExcel from '../../parts/DownloadExcel';
import moment from 'moment/moment';
import MoreDetails from '../../parts/MoreDetails';
import Frame from "../../components/frame";
import FilterOr from '../../parts/FilterOr';
import * as XLSX from 'xlsx';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export default function LeadershipLog() {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [spinner, setSpinner] = useState(false);
    const [table, setTable] = useState();
    const [userData, setUserData] = useState();
    const [refrashPage, setRefrashPage] = useState(false);
    const [searchBy, setSearchBy] = useState('');
    const [searchByText, setSearchByText] = useState('');
    const [filterShow, setFilterShow] = useState(false);
    const [clickRow, setClickRow] = useState(null);
    const [moreDetails, setMoreDetails] = useState('');
    const [country, setCountry] = useState('');
    // const [filterPackage,setFilterPackage] = useState(null);
    const [exportData,setExportData] = useState('');
    let countTable = 0;
    const list = [
        { 'label': 'Username', 'data': 'username', 'type': 'text' },
        { 'label': 'Email', 'data': 'email', 'type': 'text' },
        { 'label': 'Kyc Status', 'data': 'status_kyc', 'type': 'option', 'value': [{ 'key': 'Approved', 'value': '1' }, { 'key': 'Rejected', 'value': '2' }, { 'key': 'Pending', 'value': '0' }] },
        // {'label':'Package','data':'package','type':'option','value':filterPackage},
        { 'label': 'Status', 'data': 'status', 'type': 'option', 'value': [{ 'key': 'Active', 'value': '1' }, { 'key': 'Inactive', 'value': '0' }] },
        { 'label': 'Country', 'data': 'country', 'type': 'option', 'value': country }
    ]
    let durationanim = 0.15;
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/leadershopLog');
        setCollapseId(2);
        (async () => {
            try {
                //setSpinner(true);
                let r = await getReq('salary/incomes?export=true', sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setUserData(r.data);
                    setMoreDetails(r.more_details);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    // setFilterPackage(r.package_data);
                    //setSpinner(false);
                }
                if (r.errors) {
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                if (err.message) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                //setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    }, [refrashPage]);
    // const handleAuth = async(username)=>{
    //     let res = await getReq(`profile/${username}/get-credentails`,sessionStorage.getItem('token'));
    //     setAuth(res.data);
    //     setShowAuth(true);
    //   }
    async function searchTable(e) {
        try {
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/salary/incomes?export=true&page=${e.target.value}`, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                setUserData(r.data);
                setExportData(r.export_data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if (r.errors) {
                setSpinner(false);
                let err = Object.values(r.errors);
                notifyError(err[0][0]);
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function nextTable(url) {
        try {
            if (url !== null) {
                setSpinner(true);
                let r = await getTable(url, sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setUserData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                }
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    // function editUserProfileFun(username,name) {
    //     navigate(`/admin/editUserProfile?username=${username}&usernameparams=${name}`);
    // }
    // function filterFunData(r) {
    //     setUserData(r.data);
    //     setExportData(r.export_data);
    //     setTable(r.data.data);
    // }
    // async function changeActiveStatus(username, idx) {
    //     setClickRow(idx);
    //     let r = await getReq(`profile/${username}/change-status`, sessionStorage.getItem('token'))
    //     if (r.status === "success" || r.status === true) {
    //         notifySuccess(r.message || r.msg);
    //     }
    //     if (r.status === "success" || r.status === false) {
    //         notifyError(r.message || r.msg);
    //     }
    //     if (r.errors) {
    //         //setSpinner(false);
    //         let err = Object.values(r.errors);
    //         notifyError(err[0][0]);
    //     }
    //     setClickRow(null);
    //     setRefrashPage(true);
    // }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            {/* {filterShow && <Filter props={{ setFilterShow, url: `${process.env.REACT_APP_BASE_URL}/salary/index?export=true`, list, filterFunData }} />} */}
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                    Leadership Details
                    </h1>
                </div>
                {table&&<div className="ms-auto">
                    {table && <DownloadExcel props={{ exportData: exportData, name: 'LeadershipLoglist.xlsx' }} />}
                </div>}
            </div>
            {moreDetails && <MoreDetails props={moreDetails} />}

            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {table&&<div className="card">
                <div className="card-header align-items-center fw-bold small d-flex">
                    <span className="flex-grow-1">Leadership Details</span>
                    {/* <span className="btn btn-outline-light btn-sm" onClick={() => setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span> */}
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div>
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                    <Table responsive style={{ textAlign: 'center' }} ref={tableRef}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Username</strong>
                                </th>
                                <th>
                                    <strong>Name</strong>
                                </th>
                                {/* <th>
                                    <strong>Type</strong>
                                </th> */}
                                {/* <th>
                                    <strong>Total Weeks</strong>
                                </th> */}
                                <th>
                                    <strong>Paid Weeks</strong>
                                </th>
                                <th>
                                    <strong>Amount</strong>
                                </th>
                                <th>
                                    <strong>Next Payment</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                            </tr>
                        </thead>
                        {table && <tbody>
                            {
                                table.map((data, idx) => {
                                    countTable += 1;
                                    return (<motion.tr initial={{ opacity: 0, x: -150 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: durationanim += 0.06 }} key={idx}>
                                        <td>{data.username}</td>
                                        <td>{data.salary_name}</td>
                                        {/* <td>{data.type}</td> */}
                                        {/* <td>{data.total_weeks}</td> */}
                                        <td>{data.paid_weeks}</td>
                                        <td>$ {parseFloat(data.per_week_amount)}</td>
                                        <td>{moment(data.next_payment_date).format('DD-MM-YYYY')}</td>
                                        <td>
                                            {data.status === 0 && <span className="badge light btn btn-danger">Inactive</span>}
                                            {data.status === 1 && <span className="badge light btn btn-success">Active</span>}
                                        </td>
                                        {/* <td>
                                            {moment(data.created_at).format('MMM Do, YYYY')}
                                        </td> */}
                                    </motion.tr>)
                                })
                            }
                        </tbody>}
                        {
                            countTable === 0 && <div style={{ display: 'table-caption', marginTop: '1rem', textAlign: 'center' }}>No Data For Action!!!</div>
                        }
                    </Table>
                    {table &&
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className="h6">
                                Showing <span className="font-medium">{userData.from||'-'}</span> to <span className="font-medium">{userData.to||'-'}</span> of{' '}
                                <span className="font-medium">{userData.total}</span> results
                            </p>
                            <div className="d-flex">

                                <nav aria-label="Page navigation">
                                    <ul className="pagination mb-0">
                                        <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(userData.prev_page_url)}>
                                            <a className="page-link" aria-label="Previous">
                                                <span className="btn-link cursor-pointer">&laquo;</span>
                                                
                                            </a>
                                        </li>
                                        <li className="page-item" style={{ cursor: 'pointer' }}>
                                            <a className="page-link">{userData.current_page}</a>
                                        </li>
                                        <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(userData.next_page_url)}>
                                            <a className="page-link" aria-label="Next">
                                                <span className="btn-link">&raquo;</span>
                                                
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>}
                </div>
                
            </div>}
           
        </Frame>
    )
}
