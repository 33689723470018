import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from '../parts/Toast';
import { getReq, logout } from '../requests/request';
import { IMAGES } from '../constant/theme';
import { SidebarClick } from '../routes/route';

function Header() {
  const {pinSidebar,setPinSidebar} = useContext(SidebarClick)
  const navigate = useNavigate();
  const [img, setImg] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        let r = await getReq('get-picture', sessionStorage.getItem('token'))
        if (r.status === "success" || r.status === true) {
          setImg(r.data);
        }
        if (r.status == false && r.msg == 401) {
          sessionStorage.clear();
          window.history.replaceState(null, null, "/");
          navigate('/', { replace: true });
          //notifyError('Authentication Failed. Please Login Again....');
        }
      }
      catch (err) {
        if (err.message) {
          sessionStorage.clear();
          window.history.replaceState(null, null, "/");
          navigate('/', { replace: true });
        }
      }
    })()
  }, []);

  async function onLogout() {
    let result = await logout(sessionStorage.getItem('token'));
    if(result.errors){
      let err = Object.values(result.errors);
      //err.map((data)=>notifyError(data[0]));
      notifyError(err[0][0]);
    }
    if(result.status===true){
      sessionStorage.clear();
      window.history.replaceState(null, null, "/");
      navigate('/',{ replace: true });
      notifySuccess('Logout Successfully');
    }
  }
    
  return (
    <div className="app-header d-flex align-items-center">

    <div className="d-flex">
    <button className="pin-sidebar"><i className="bi bi-list lh-1"></i></button>
    </div>

      {/* <div className="d-flex">
        <button className="pin-sidebar">
          <i className="bi bi-list lh-1"></i>
        </button>
      </div> */}

      <div className="app-brand-sm d-lg-none d-flex">
      <a href="/user/dashboard">
        <img src="/assets/img/logo-white.png" className="logo" alt="" />
      </a>
    </div>

      <div className="header-actions">
        <div className="dropdown ms-4">
          <a id="userSettings" className="dropdown-toggle d-flex py-2 align-items-center" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             <img className="rounded-4 img-3x" src={(img && `${process.env.REACT_APP_IMG_URL}/${img}`) || IMAGES.Avatar} alt="Profile" height="60" />
            <div className="ms-2 text-truncate d-lg-flex flex-column d-none">
              <p className="text-truncate m-0">{sessionStorage.getItem('username').toLowerCase()}</p>
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-end shadow-lg">
            <a href="/admin/profileSetting" className="dropdown-item d-flex align-items-center"><i
                className="bi bi-person fs-5 me-2"></i>My Profile</a>
            {/* <a href="/admin/kycList" className="dropdown-item d-flex align-items-center"><i
                className="bi bi-file-text fs-5 me-2"></i>KYC</a> */}
            <a href="/admin/packageList" className="dropdown-item d-flex align-items-center"><i
                className="bi bi-box fs-5 me-2"></i>Package</a>
            <div className="mx-3 mt-2 d-grid">
              <a onClick={()=>onLogout()} className="btn btn-primary">Logout</a>
            </div>
          </div>
        </div>
        <div className="d-flex" onClick={()=>setPinSidebar(!pinSidebar)}>
          <button className="toggle-sidebar">
            <i className="bi bi-list lh-1"></i>
          </button>
        </div>
      </div>

    </div>
  );
}
export default Header;