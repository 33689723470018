import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SidebarClick } from '../../routes/route';
import { getReq, getTable, postReq } from '../../requests/request';
import { notifyError } from '../../parts/Toast';
import {Col,Card,Table, Spinner} from "react-bootstrap";
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import MoreDetails from '../../parts/MoreDetails';
import moment from 'moment/moment';
import { IMAGES } from '../../constant/theme';
import Frame from "../../components/frame";
import DownloadExcel from '../../parts/DownloadExcel';
export default function AllTransaction() {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [payoutsListData,setPayoutsListData] = useState('');
    const [refrashPage,setRefrashPage] = useState(false);
    const [filterShow,setFilterShow] = useState(false);
    const [moreDetails,setMoreDetails] = useState(null);
    const [filterSource,setFilterSource] = useState(null);
    const [exportData,setExportData] = useState('');
    let countTable = 0;
    let durationanim = 0.15;
    let AmountSymbol = '$';
    const list=[
        {'label':'Username','data':'username','type':'text'},
        {'label':'Income Source','data':'source','type':'option','value':filterSource},
    ]
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/allTransaction');
        setCollapseId(11);
        (async()=>{
            try{
                //setSpinner(true);
                let r = await getReq('transactions?export=true',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setPayoutsListData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setFilterSource(r.source);
                    setMoreDetails(r.more_details);
                    //setSpinner(false);
                }
                if(r.errors){
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                //setSpinner(false);
                notifyError("Request failed please login again");
            }
        })()
    },[refrashPage]);
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setPayoutsListData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/transactions?export=true&page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setPayoutsListData(r.data);
                setExportData(r.export_data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    function filterFunData(r){
        console.log('>>>>',r);
        setPayoutsListData(r.data);
        setExportData(r.export_data);
        setTable(r.data.data);
        setMoreDetails(r.more_details);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
             <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                    Account Statement
                    </h1>
                </div>
                <div className="ms-auto">
                    {table&&<DownloadExcel props={{exportData: exportData,name:'datewise-transactions.xlsx'}}/>}
                </div>
            </div>
            {moreDetails&&<MoreDetails props={moreDetails}/>}
            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}
            {table&&<div className="card">
                <div className="card-header align-items-center fw-bold small d-flex">
                    <span className="flex-grow-1">Account Statement List</span>
                    <span className="btn btn-outline-light btn-sm" onClick={() => setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div>
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                    <Table responsive style={{textAlign:'center'}} ref={tableRef}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Username</strong>
                                </th>
                                <th>
                                    <strong>Amount</strong>
                                </th>
                                <th>
                                    <strong>Source</strong>
                                </th>
                                <th>
                                    <strong>Wallet</strong>
                                </th>
                                <th>
                                    <strong>Type</strong>
                                </th>
                                {/* <th>
                                    <strong>Value</strong>
                                </th>
                                <th>
                                    <strong>Description</strong>
                                </th>
                                <th>
                                    <strong>Source</strong>
                                </th>
                                <th>
                                    <strong>Transaction Time</strong>
                                </th> */}
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>{
                                countTable +=1;
                                return(<motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                                    <td>{data.username}</td>
                                    <td>{process.env.REACT_APP_Amount_Symbol||'$'} {parseFloat(data.value)}</td>
                                    <td>{data.source||'-'}</td>
                                    <td>{data.wallet=='pay_out'?'Pay Out':data.wallet=='pay_in'?'Pay In':'NA'}</td>
                                    <td>{data.type}</td>
                                    {/* <td>{process.env.REACT_APP_Amount_Symbol} {parseFloat(data.value)}</td>
                                    <td>{data.note}</td>
                                    <td>{data.source||'-'}</td>
                                    <td>{moment(data.created_at).format('DD-MM-YYYY HH:mm')}</td> */}
                                </motion.tr>)
                                })
                            }
                        </tbody>}
                        {
                            countTable===0&& <div style={{display:'table-caption',marginTop:'1rem',textAlign:'center'}}>No Data For Action!!!</div>
                        }
                    </Table>
                    {table&&
                    <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                        <p className="h6">
                            Showing <span className="font-medium">{payoutsListData.from||'-'}</span> to <span className="font-medium">{payoutsListData.to||'-'}</span> of{' '}
                            <span className="font-medium">{payoutsListData.total}</span> results
                        </p>
                        <div className="d-flex">
                            
                            <nav aria-label="Page navigation">
                                <ul className="pagination mb-0">
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(payoutsListData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link cursor-pointer">&laquo;</span>
                                            
                                        </a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}}>
                                        <a className="page-link">{payoutsListData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(payoutsListData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
                </div>
                
            </div>}
        {filterShow&&<Filter props={{setFilterShow,url:`${process.env.REACT_APP_BASE_URL}/transactions?export=true`,list,filterFunData}}/>}
        
        </Frame>
    )
}
