import React, { useContext, useEffect, useState } from 'react';
import {Col,Card,Table, Spinner} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { getReq, getTable, postReq } from '../../requests/request';
import { SidebarClick } from '../../routes/route';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Filter from '../../parts/Filter';
import './PriceList.css';
import { IMAGES } from '../../constant/theme';
import Frame from "../../components/frame";

export default function Pakage() {
    const [classList,setClassList] = useState(['pl-container1','pl-container2','pl-container3'])
    let classListId = null;
    const navigate = useNavigate();
    const [username,setUsername] = useState('admin');
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [pakageListData,setPakageListData] = useState();
    const [refrashPage,setRefrashPage] = useState(false);
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [showPakage,setShowPakage] = useState(false);
    const [investmentAmount,setInvestmentAmount] = useState('');
    const [loader,setLoader] = useState(false);
    const [pakageId,setPackageId] = useState();
    const [tempVariable,setTempVariable] = useState();
    const [maxPrice,setMaxPrice] = useState();
    const [minPrice,setMinPrice] = useState();
    const [activeCard,setActiveCard] = useState(null);
    const [error,setError] = useState('');
    const [filterShow,setFilterShow] = useState(false);
    const [modalLoader,setModalLoader] = useState(false);
    const [success,setSuccess] = useState(false);
    const [variablePackageAlert,setVariablePackageAlert] = useState(false);
    const [fixedPackageAlert,setFixedPackageAlert] = useState(false);
    const [buyVeriablePackageData,setBuyVeriablePackageData] = useState({});
    const [buyFixedPackageData,setBuyFixedPackageData] = useState({});
    const [showSponsorMsg,setShowSponsoreMsg] = useState('');
    const [showFPakage,setShowFPakage] = useState(false);
    const [showPack,setShowPack] = useState(false);
    const [icoloader,setIcoloader] = useState(false);
    const [showSponsorCheck,setShowSponsorCheck] = useState(false);
    const [wallet,setWallet] = useState('pay_in');
    let AmountSymbol = '$';
    const list=[
        {'label':'Name','data':'name','type':'text'},
        {'label':'Username','data':'username','type':'text'},
        {'label':'Status','data':'status','type':'option'}
    ]
    // useEffect(()=>{
    //     (async ()=>{
    //         let br = await getReq('pre-register');
    //         sessionStorage.setItem('Binary',br.binary);
    //     })()
    // },[refrashPage]);
    // useEffect(()=>{
    //     (async()=>{
    //         let r = await getReq('profile',sessionStorage.getItem('token'))
    //         if(r.status==="success" || r.status===true){
    //             setUsername(r.data.username);
    //         }
    //     })()
    // },[]);
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/pakageList');
        setCollapseId(12);
        (async()=>{
            try{
                console.log('show package',fixedPackageAlert,showFPakage)
                setSpinner(true);
                let r = await getReq('package/index?status=1',sessionStorage.getItem('token'));
                console.log(r)
                if(r.status==="success" || r.status===true){
                    setPakageListData(r.data);
                    setTable(r.data.data);
                    setActiveCard(parseInt(r.data.data.length/2));
                    setSpinner(false);
                }
                if(r.status==false && r.msg==401){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                    notifyError('authentication failed. Login Again');
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    err.map((data)=>notifyError(data[0]));
                }
            }
            catch(err){
                if(err.response.status===401||err.response.status===404){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
				    navigate('/',{ replace: true });
                }
                setSpinner(false);
                notifyError("Request failed login again");
            }
        })()
    },[refrashPage]);
    // async function nextTable(url){
    //     try{
    //         if(url!==null){
    //             setSpinner(true);
    //             let r = await getTable(url,sessionStorage.getItem('token'))
    //             if(r.status==="success" || r.status===true){
    //                 setPakageListData(r.data);
    //                 setTable(r.data.data);
    //                 setSpinner(false);
    //             }
    //             if(r.errors){
    //                 setSpinner(false);
    //                 let err = Object.values(r.errors);
    //                 err.map((data)=>notifyError(data[0]));
    //             }
    //         }
    //     }
    //     catch(err){
    //         setSpinner(false);
    //         notifyError("Request failed login again");
    //     }
    // }
    // async function searchTable(e){
    //     try{
    //         setSpinner(true);
    //         let r = await getTable(`${process.env.REACT_APP_BASE_URL}/subscription?page=${e.target.value}`,sessionStorage.getItem('token'))
    //         if(r.status==="success" || r.status===true){
    //             setPakageListData(r.data);
    //             setTable(r.data.data);
    //             setSpinner(false);
    //         }
    //         if(r.errors){
    //             setSpinner(false);
    //             let err = Object.values(r.errors);
    //             err.map((data)=>notifyError(data[0]));
    //         }
    //     }
    //     catch(err){
    //         setSpinner(false);
    //         notifyError("Request failed login again");
    //     }
    // }
    async function pakageBuyFixed(){
        setFixedPackageAlert(false);
        let temp = {"package_id":buyFixedPackageData.id,'subscription_type':'fixed','investment_amount':buyFixedPackageData.price,'username':username,'wallet':wallet}
        setModalLoader(true);
        let r = await postReq('admin/subscription/store',temp,sessionStorage.getItem('token'))
        if(r.status==="success" || r.status===true){
            setShowFPakage(false)
            setModalLoader(false);
            setUsername('admin');
            //notifySuccess(r.message||r.msg);
            setSuccess(true);
            let time = setTimeout(function() {
                clearTimeout(time);
                setSuccess(false);
                navigate('/admin/subscriptionList');
            }, 6000);
        }
        if(r.status==false && r.msg==401){
            setModalLoader(false);
            sessionStorage.clear();
            window.history.replaceState(null, null, "/");
            navigate('/',{ replace: true });
            notifyError('authentication failed. Login Again');
        }
        if(r.status===false){
            setModalLoader(false);
            notifyError(r.message||r.msg);
        }
        if(r.error){
            setSpinner(false);
            let err = Object.values(r.error);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0])
        }
    }
    async function buyPakageFun(){
        let temp = {"package_id":buyVeriablePackageData.id,'subscription_type':'variable','investment_amount':investmentAmount,'username':username}
        if(parseFloat(investmentAmount)>=parseFloat(buyVeriablePackageData.minimum_price) && (buyVeriablePackageData.maximum_price!='Above'?parseFloat(investmentAmount) <= parseFloat(buyVeriablePackageData.maximum_price):true)){
            setError("");
            setModalLoader(true);
            let r = await postReq('admin/subscription/store',temp,sessionStorage.getItem('token'));
            if(r.status==="success" || r.status===true){
                setModalLoader(false);
                setInvestmentAmount('');
                //notifySuccess(r.message||r.msg);
                setShowPakage(false);
                setUsername('admin');
                setSuccess(true);
                let time = setTimeout(function() {
                    clearTimeout(time);
                    setSuccess(false);
                    navigate('/admin/subscriptionList');
                }, 6000);
            }
            if(r.status==false && r.msg==401){
                setModalLoader(false);
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
                notifyError('authentication failed. Login Again');
            }
            if(r.status===false){
                setModalLoader(false);
                notifyError(r.message||r.msg);
            }
            if(r.error){
                setSpinner(false);
                let err = Object.values(r.error);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0])
            }
            setModalLoader(false);
        }
        else{
            setError("Amount Should be b/w range!!!")
        }
    }
    function showVBuyAlert(id,minimum_price,maximum_price){
        let temp = {...buyVeriablePackageData}
        temp['id'] = id;
        temp['minimum_price'] = minimum_price;
        // temp['maximum_price'] = maximum_price;
        temp['maximum_price'] = maximum_price<=10000000?maximum_price:'Above';
        setBuyVeriablePackageData(temp);
        setVariablePackageAlert(true);
    }
    function showFBuyAlert(id,price){
        let temp = {...buyFixedPackageData}
        temp['id'] = id;
        temp['price'] = price;
        setBuyFixedPackageData(temp);
        setFixedPackageAlert(true);
    }
    async function checkSponsor(){
        setShowSponsorCheck(true);
		setShowSponsoreMsg('');
		let r = await getReq(`check/${username}/sponsor`);
		setShowSponsoreMsg(r);
        setShowSponsorCheck(false);
        setShowPack(true);
	}
    function handleStr(e){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9]/ig, '');
        setUsername(value);
	};
    // function filterFunData(r){
    //     setSpinner(true);
    //     setPakageListData(r.data);
    //     setTable(r.data.data);
    //     setSpinner(false);
    // }
    // async function syncwallet(){
    //     setIcoloader(true);
    //     let r = await getReq('get/ico',sessionStorage.getItem('token'));
    //     if(r.code==200){
    //         setShowPack(true);
    //         notifySuccess('Sync Successfully');
    //     }
    //     else{
    //         setShowPack(false);
    //         notifyError('!!! Please Try After Sometimes');
    //     }
    //     setIcoloader(false);
    // }
    return (
        
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-md-3 mb-2 justify-content-between">
                <h1 className="page-header mb-0"> Buy Package</h1>
                {/* {!showPack&&table&&<button type="button" onClick={()=>syncwallet()} className="btn btn-primary" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px",height:'3em' }}>
                    ICO Purchase
                    {icoloader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>}
                </button>} */}
            </div>
            <Col lg={12}>
                <Card>
                {/* {filterShow&&<Filter props={{setFilterShow,url:`${process.env.REACT_APP_BASE_URL}/user-list`,list,filterFunData}}/>} */}
                <div className='bard-body'>
                    {/* {!showPack&&<div style={{position:'absolute',width:'100%',height:'100%',zIndex:9999,background:'rgba(0,0,0,0.8)'}}>
                        <div style={{display:'flex',height:'100%',width:'100%',justifyContent:'center',alignItems:'center',fontSize:'large',fontWeight: 600}}>
                            To Buy Package, Click ICO Purchase.
                        </div>
                    </div>} */}
                    <div style={{textAlign:'center'}}>
                        <h2 className="pl-pricingTable-title">Find a plan that's right for members.</h2>
                        <h3 className="pl-pricingTable-subtitle">Every plan comes with best price.</h3>
                    </div>
                    <div className="pl-outer-container">
                        <span className="light" style={{position:'relative',fontSize:'x-large',fontFamily:'monospace',color:'#b371dd',marginBottom:'2.2rem'}}>Pricing Table</span>
                        <div className="pl-card-body">
                            {/* <div className="pl-container pl-container1">
                                <div className="pl-inner_container">
                                <div className="pl-title">Individual</div>
                                <div className="pl-main_number">2</div>
                                <div className="pl-container_text">
                                    <div className="pl-container_text1">Simple</div>
                                    <div className="pl-container_text2">20 gb</div>
                                </div>
                                </div>
                                <a><span>Continue</span></a>
                            </div>
                            <div className="pl-container pl-container2 active">
                                <div className="pl-inner_container">
                                <div className="pl-title">Shared</div>
                                <div className="pl-main_number">6</div>
                                <div className="pl-container_text">
                                    <div className="pl-container_text1">Family</div>
                                    <div className="pl-container_text2">100 gb</div>
                                </div>
                                </div>
                                <a><span>Continue</span></a>
                            </div> */}
                            {table&&table.map((data,idx)=>{
                                classListId += 1;
                                if(classListId>2){
                                    classListId=0;
                                }
                                return(<div key={idx} className={`pl-container ${classList[classListId]} ${idx===activeCard?'pl-active':''}`} onClick={()=>setActiveCard(idx)}>
                                    <div className="pl-inner_container">
                                    <div className="pl-title">{data.name}</div>
                                    {/* <div className="pl-main_number">{data.type=='fixed'?parseFloat(data.price):`${parseFloat(data.minimum_price)} - ${parseFloat(data.maximum_price)}`}</div> */}
                                    <div className="pl-main_number">{data.type=='fixed'?<div><small>$</small>{parseFloat(data.price)}</div>: <div><small>$</small> {parseFloat(data.minimum_price)} - {parseFloat(data.maximum_price)<=10000000?parseFloat(data.maximum_price):'Above'}</div>}</div>
                                    {/* <div className="pl-container_text">
                                        <div className="pl-container_text1" style={{fontFamily: 'system-ui',fontWeight:'600'}}>Duration(Months) : {data.duration}</div>
                                        {data.is_roi==1&&<div className="pl-container_text1" style={{fontFamily: 'system-ui',fontWeight:'600'}}>Roi: {parseFloat(data.roi_percentage)}%</div>}
                                        {data.is_binary==1&&<div className="pl-container_text1" style={{fontFamily: 'system-ui',fontWeight:'600'}}>Binary: {parseFloat(data.binary_percentage)}%</div>}
                                    </div> */}
                                    </div>
                                    {/* <a><span>Buy Now</span></a> */}
                                    {data.type==='variable'&&<a onClick={()=>{showVBuyAlert(data.id,data.minimum_price,data.maximum_price);setPackageId(data.id)}}>
                                        <span style={{display:'flex',justifyContent: 'center',alignItems: 'center',gap: '0.5rem'}} className='packageBuy'>
                                        Buy
                                        {/* {data.id===pakageId&&loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>} */}
                                    </span></a>}
                                    {data.type==='fixed'&&<a onClick={()=>{showFBuyAlert(data.id,data.price);setPackageId(data.id)}}>
                                        <span style={{display:'flex',justifyContent: 'center',alignItems: 'center',gap: '0.5rem'}} className='packageBuy'>
                                        Buy
                                        {/* {data.id===pakageId&&loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>} */}
                                    </span></a>}
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
                </Card>
            </Col>

            {/* <Col lg={12}>
                <Card>
                    {spinner&&<div style={{background:'rgba(0,0,0,0.5)',position:'absolute',width:'100%',height:'100%'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                    <Card.Header>
                        <Card.Title>Pakage List</Card.Title>
                        <div className="d-flex gap-2">
                            <span style={{cursor:'pointer',fontSize:'16px',fontFamily:'auto',color:'var(--primary)'}} onClick={()=>setFilterShow(true)}>Filter</span> */}
                            {/* <span style={{cursor:'pointer',fontSize:'16px',fontFamily:'auto',color:'var(--primary)'}} onClick={()=>setFilterShow(true)}>Filter</span> */}
                            {/* <div className="form-group">
                                <select name="select_box" className="form-control" onChange={(e)=>setSearchBy(e.target.value)}>
                                    <option value="">Select Search Category</option>
                                    <option value={'bank_name'}>Bank Name</option>
                                    <option value={'date'}>Date</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={searchByText} placeholder="Search" onChange={searchByFun}/>
                            </div> */}
                        {/* </div>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive style={{textAlign:'center'}}>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Name</strong>
                                    </th>
                                    <th>
                                        <strong>Type</strong>
                                    </th>
                                    <th>
                                        <strong>Price</strong>
                                    </th>
                                    <th>
                                        <strong>Minimum Price</strong>
                                    </th>
                                    <th>
                                        <strong>Maximum Price</strong>
                                    </th>
                                    <th>
                                        <strong>Duration</strong>
                                    </th>
                                    <th>
                                        <strong>Status</strong>
                                    </th>
                                    <th>
                                        <strong>Action</strong>
                                    </th>
                                </tr>
                            </thead>
                            {table&&<tbody>
                                {
                                    table.map((data,idx)=>
                                    <tr key={idx}>
                                        <td>{data.name}</td>
                                        <td>{data.type}</td>
                                        <td>{data.price}</td>
                                        <td>{data.minimum_price}</td>
                                        <td>{data.maximum_price}</td>
                                        <td>{data.duration}</td>
                                        <td>
                                            {data.status===0 && <span  className="badge light btn btn-warning">Inactive</span>}
                                            {data.status===1 && <span  className="badge light btn btn-success">Active</span>}
                                        </td> */}
                                        {/* <td>
                                            <button type="button" className="btn btn-primary btn-sm" onClick={()=>{setShowEditModel(true);editBankFun(data.id)}}>Edit</button>
                                        </td> */}
                                        {/* <td>
                                            {data.type==='variable'&&<a type="button" className="d-flex gap-2 align-items-center justify-content-center" style={{color:"var(--primary)"}} onClick={()=>{pakageBuyvariable(data.id,data.minimum_price,data.maximum_price);setPackageId(data.id)}}>
                                                Buy
                                                {loader&&data.id===pakageId&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>}
                                            </a>}
                                            {data.type==='fixed'&&<a type="button" className="d-flex gap-2 align-items-center justify-content-center" style={{color:"var(--primary)"}} onClick={()=>{pakageBuyFixed(data.id,data.price);setPackageId(data.id)}}>
                                                Buy
                                                {loader&&data.id===pakageId&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>}
                                            </a>}
                                        </td>
                                    </tr>)
                                }
                            </tbody>}
                        </Table> */}
                        {/* {table&&<div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                            <p className="h6">
                                Showing <span className="font-medium">{pakageListData.from||'-'}</span> to <span className="font-medium">{pakageListData.to||'-'}</span> of{' '}
                                <span className="font-medium">{pakageListData.last_page}</span> results
                            </p>
                            <div className="d-flex">
                                <div className="input-group">
                                    <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(pakageListData.prev_page_url)}>
                                            <a className="page-link" aria-label="Previous">
                                                <span className="btn-link text-black cursor-pointer">&laquo;</span>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                        </li>
                                        <li className="page-item" style={{cursor:'pointer'}}>
                                            <a className="page-link">{pakageListData.current_page}</a>
                                        </li>
                                        <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(pakageListData.next_page_url)}>
                                            <a className="page-link" aria-label="Next">
                                                <span className="btn-link text-black">&raquo;</span>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>}
                    </Card.Body> */}
                {/* </Card>
            </Col> */}
            {showPakage&&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                    <Modal className="fade" show={true}>
                        <Modal.Header closeButton onClick={()=>{setShowPakage(false);setUsername('admin');setShowSponsoreMsg('')}}>
                            <Modal.Title>Buy Pakage</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div>
                            <div className="mb-1">
                                <label className="mb-1">Username</label>
                                <input type="text" className="form-control" value={username} onChange={(e) => handleStr(e)} onBlur={()=>checkSponsor()} autoFocus={true}/>
                            </div>
                            {!showSponsorMsg&&showSponsorCheck&&<span className='mb-1'>Username: <div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></span>}
                            {showSponsorMsg.data=="Available"&&<span>Username: <span style={{color:'rgb(70 101255)'}}>{showSponsorMsg.info}</span></span>}
                            {showSponsorMsg.data=="Not Available"&&<div><img src={IMAGES.wrong} style={{width:'3%',height:'3%'}}/><span> Not Available</span></div>}
                            </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Investment Amount</label>
                                <input type="number" className="form-control" value={investmentAmount} onChange={(e)=>setInvestmentAmount(e.target.value)} disabled={!showPack}/>
                            </div>
                        </div>
                        <div>Investment Amount Should be Between {process.env.REACT_APP_Amount_Symbol}{parseFloat(buyVeriablePackageData.minimum_price)} and {buyVeriablePackageData.maximum_price!='Above'&&process.env.REACT_APP_Amount_Symbol}{buyVeriablePackageData.maximum_price!='Above'?parseFloat(buyVeriablePackageData.maximum_price):'Above'}</div>
                        <div style={{color:'red',marginBottom:'10px'}}>{error}</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{setShowPakage(false);setUsername('admin');setShowSponsoreMsg('')}}>Close</Button>
                            {showPack&&<Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={()=>showPack&&buyPakageFun()}>
                                Buy
                                {modalLoader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>}
                            </Button>}
                            {!showPack&&<Button className='d-flex justify-content-center align-items-center gap-2' style={{cursor:'not-allowed',background:'#385134 !important'}} variant="primary">
                                Buy
                            </Button>}
                        </Modal.Footer>
                    </Modal>
                // </div>
            }
            {showFPakage&&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                    <Modal className="fade" show={true}>
                        <Modal.Header closeButton onClick={()=>{setShowFPakage(false);setUsername('admin');setShowSponsoreMsg('')}}>
                            <Modal.Title>Buy Pakage</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div>
                            <div className="mb-1">
                                <label className="mb-1">Username</label>
                                <input type="text" className="form-control" value={username} onChange={(e) => handleStr(e)} onBlur={()=>checkSponsor()} autoFocus={true}/>
                            </div>
                            {!showSponsorMsg&&showSponsorCheck&&<span className='mb-1'>Username: <div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div></span>}
                            {showSponsorMsg.data=="Available"&&<span className='mb-1'>Username: <span style={{color:'rgb(70 101 255)'}}>{showSponsorMsg.info}</span></span>}
                            {showSponsorMsg.data=="Not Available"&&<div className='mb-1'><img src={IMAGES.wrong} style={{width:'4%',height:'4%'}}/><span> Not Available</span></div>}
                            <div className="mb-1">
                                <label className="text-label">Wallet</label>
                                <select className="form-control" name='wallet' value={wallet} onChange={(e)=>setWallet(e.target.value)} required>
                                    <option value={'pay_in'}>Pay In</option>
                                    <option value={'credits'}>Credit</option>
                                </select>
                            </div>
                        </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{setShowFPakage(false);setShowSponsoreMsg('');setUsername('admin')}}>Close</Button>
                            {showPack&&<Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={()=>pakageBuyFixed()}>
                                Buy
                                {modalLoader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>}
                            </Button>}
                            {!showPack&&<Button className='d-flex justify-content-center align-items-center gap-2' style={{cursor:'not-allowed',background:'#385134 !important'}} variant="primary">
                                Buy
                            </Button>}
                        </Modal.Footer>
                    </Modal>
                // </div>
            }
            {
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                    success&&<Modal className="fade" show={true}>
                        <Modal.Body style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <img src={IMAGES.buy} style={{width:'50%',height:'50%'}}/>
                            <span style={{fontFamily:'inherit'}}>
                                Package Subscribed Successfully
                            </span>
                        </Modal.Body>
                    </Modal>
                // </div>
            }
            {
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                    variablePackageAlert&&<Modal className="fade" show={true}>
                        <Modal.Header closeButton onClick={()=>setVariablePackageAlert(false)}>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontFamily:'inherit'}}>
                                Are You Sure To Buy This Package ?
                            </span>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>setVariablePackageAlert(false)}>Close</Button>
                            <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={()=>{setShowPakage(true);setVariablePackageAlert(false)}}>
                                Continue
                            </Button>
                        </Modal.Footer>
                    </Modal>
                // </div>
            }
            {
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                    fixedPackageAlert&&<Modal className="fade" show={true}>
                        <Modal.Header closeButton onClick={()=>setFixedPackageAlert(false)}>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontFamily:'inherit'}}>
                                Are You Sure To Buy This Package ?
                            </span>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>setFixedPackageAlert(false)}>Close</Button>
                            <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={()=>{setFixedPackageAlert(false);setShowFPakage(true)}}>
                                Continue
                            </Button>
                        </Modal.Footer>
                    </Modal>
                // </div>
            }
        </Frame>
    )
}
